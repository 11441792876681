import {NgModule} from "@angular/core";
import {I18nConfigurationModule, I18nSharedModule, I18nConfiguration} from "@kwsoft/common-ng-i18n";

export const i18nConfiguration: I18nConfiguration = {
    defaultLanguage: "de",
    languages: ["de", "fr", "it", "en"],
    defaultCurrencyCode: "CHF",
    defaultCurrencyFractionDigits: 0
};

@NgModule({
    imports: [
        I18nConfigurationModule.forRoot(i18nConfiguration),
        I18nSharedModule
    ],
    exports: [I18nSharedModule]
})
export class I18nModule {
}
