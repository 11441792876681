import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {environment} from "./environments/environment";
import {fetchAndRegisterTranslations} from "@kwsoft/common-ng-i18n";
import {i18nConfiguration} from "./app/i18n/i18n.module";

if (environment.production) {
    enableProdMode();
}

// wait until the backend translations are fetched from the backend and registered in the angular app
fetchAndRegisterTranslations(i18nConfiguration.languages, i18nConfiguration.defaultLanguage, window.navigator.language)
    .then(() => import("./app/app.module"))
    .then(appModuleFile => platformBrowserDynamic()
        .bootstrapModule(appModuleFile.AppModule))
    .catch(err => console.error(err));
