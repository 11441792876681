const kwAppEnvOrDefault = (propertyName: string, defaultValue: string): string => {
    const anyWindow: any = window;
    return anyWindow?.kwAppEnv?.[propertyName] ?? defaultValue;
};

export const environment = {
    production: true,
    // env vars read from the environment using the ../assets/kw-app-env.js file
    // those defaults here are used for dev builds (if kw-app-env.js is not substituted)
    profiles: kwAppEnvOrDefault("profiles", "n/a"),
    stageBannerVisible: kwAppEnvOrDefault("stageBannerVisible", "true") === "true",
    stageBannerTextColor: kwAppEnvOrDefault("stageBannerTextColor", "#ffffff"),
    stageBannerBackgroundColor: kwAppEnvOrDefault("stageBannerBackgroundColor", "#188310"),
    stageBannerTextDe: kwAppEnvOrDefault("stageBannerTextDe", "Testumgebung"),
    stageBannerTextFr: kwAppEnvOrDefault("stageBannerTextFr", "Environnement de test"),
    stageBannerTextIt: kwAppEnvOrDefault("stageBannerTextIt", "Ambiente di prova"),
    stageBannerDescriptionDe: kwAppEnvOrDefault("stageBannerDescriptionDe", "localhost"),
    stageBannerDescriptionFr: kwAppEnvOrDefault("stageBannerDescriptionFr", "localhost"),
    stageBannerDescriptionIt: kwAppEnvOrDefault("stageBannerDescriptionIt", "localhost"),
    oAuth2PreauthIssuerUrl: kwAppEnvOrDefault("oAuth2PreauthIssuerUrl", "https://financing-master.bni-dev.net/auth/realms/Financing"),
    oAuth2PreauthClientId: kwAppEnvOrDefault("oAuth2PreauthClientId", "financing-frontend-client"),
    montoHost: kwAppEnvOrDefault("montoHost", "https://anbahnung.financing.local")
};
